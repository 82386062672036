import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Missing from "./components/Missing";
import Unauthorized from "./components/Unauthorized";
import Perfil from "./components/Perfil";
import RequireAuth from "./components/RequireAuth";
import { Routes, Route } from "react-router-dom";
import PersistLogin from "./components/PersistLogin";
import CuentaComponent from "./components/Cuenta";
import Layout from "./components/Layout";
import LandingPage from "./components/LandingPage";
import Demo from "./components/Demo";
import Admin from "./components/Admin";
import Verify from "./components/Verify";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/resetPassword";

const ROLES = {
  user: 100,
  admin: 200,
};

function App() {
  return (
    <Routes>
      {/* public routes */}
      <Route path="/" element={<LandingPage />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="verify" element={<Verify />} />
      <Route path="unauthorized" element={<Unauthorized />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />

      {/* we want to protect these routes */}
      <Route element={<PersistLogin />}>
        <Route element={<Layout />}>
          {/* Aquí incluimos el Layout en la jerarquía de rutas */}
          <Route
            element={<RequireAuth allowedRoles={[ROLES.user, ROLES.admin]} />}
          >
            <Route path="/home" element={<Home />} />
            <Route path="home/:id" element={<CuentaComponent/>} />
            <Route path="/perfil" element={<Perfil />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.admin]} />}>
            <Route path="/admin" element={<Admin />} />
          </Route>
        </Route>
      </Route>
      {/* catch all */}
      <Route path="*" element={<Missing />} />
    </Routes>
  );
}

export default App;
