import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "../styles/resetPassword.css"; // Crea este archivo CSS para personalizar el estilo si es necesario
import { postResetPassword } from "../api/axios";
import logo from "../assets/logo.png";
import "../styles/resetPassword.css";

const ResetPassword = () => {
  const { token } = useParams(); // Obtén el token de la URL
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrMsg("");
    setSuccessMsg("");

    if (password !== confirmPassword) {
      setErrMsg("Las contraseñas no coinciden");
      return;
    }

    try {
      const response = await postResetPassword({ password, token });
      if (response.status === 200) {
        setSuccessMsg(
          "Contraseña restablecida correctamente. Ahora puedes iniciar sesión."
        );
        setPassword("");
        setConfirmPassword("");
        setTimeout(() => {
          navigate("/login");
        }, 3000); // Redirige al login después de 3 segundos
      } else {
        setErrMsg("Hubo un problema al intentar restablecer la contraseña.");
      }
    } catch (error) {
      setErrMsg(
        error.response
          ? error.response.data.message
          : "Error al restablecer la contraseña"
      );
    }
  };

  return (
    <div className="App">
      <form onSubmit={handleSubmit}>
        <div className="form-inner">
          <div className="logo">
            <img src={logo} alt="logo" className="logo" />
          </div>
          <h2>Restablecer Contraseña</h2>
          {errMsg && (
            <div className="error-box">
              <div className="error">{errMsg}</div>
            </div>
          )}
          {successMsg && (
            <div className="success-box">
              <div className="success">{successMsg}</div>
            </div>
          )}
          <div className="form-group" style={{ position: "relative" }}>
            <label htmlFor="password">Nueva Contraseña:</label>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              onChange={(e) => setPassword(e.target.value.trim())}
              value={password}
              required
            />
            <button
              type="button"
              onClick={toggleShowPassword}
              className="show-password-btn"
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          </div>
          <div className="form-group" style={{ position: "relative" }}>
            <label htmlFor="confirm-password">Confirmar Contraseña:</label>
            <input
              type={showPassword ? "text" : "password"}
              id="confirm-password"
              onChange={(e) => setConfirmPassword(e.target.value.trim())}
              value={confirmPassword}
              required
            />
            <button
              type="button"
              onClick={toggleShowPassword}
              className="show-password-btn"
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          </div>
          <input type="submit" value="Restablecer Contraseña" />
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
