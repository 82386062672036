import { Bar } from "react-chartjs-2";
import { useState, useEffect } from "react";
import { getGraficoBarrasMensual } from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import "../../styles/app.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const GraficoBarrasMensual = () => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  const PerfilContext = useAuth();
  const token = PerfilContext.auth.accessToken;

  useEffect(() => {
    const fetchGraficoBarra = async () => {
      const response = await getGraficoBarrasMensual(token);
      if (response.status === 200) {
        setChartData(response.data);
      }else{
        console.error("Error al buscar los datos del gráfico:", response);
      }
    }
    fetchGraficoBarra();
  }, [token]);

  const options = {
    plugins: {
      title: {
        display: true,
        text: "Gastos y Ganancias por Mes",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const hasData = chartData.labels.length > 0 && chartData.datasets.length > 0;
  return (
    <div className="graficos-container">
      {hasData ? (
        <Bar data={chartData} options={options} />
      ) : (
        <p>Realiza movimientos para ver los gráficos</p>
      )}
    </div>
  );
};

export default GraficoBarrasMensual;
