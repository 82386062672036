import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/login.css";
import { postForgotPassword } from "../api/axios";
import logo from "../assets/logo.png";

const ForgotPassword = () => {
  const emailRef = useRef();

  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrMsg("");
    setSuccessMsg("");

    if (email === "") {
      setErrMsg("Por favor, ingresa tu correo electrónico");
      return;
    }

    try {
      const response = await postForgotPassword(email);
      if (response.status === 200) {
        setSuccessMsg(
          "Se ha enviado un enlace de recuperación a tu correo electrónico."
        );
        setEmail("");
        setFormSubmitted(true); // Establecer formSubmitted en true
      } else {
        setErrMsg(
          "Hubo un problema al intentar recuperar la contraseña. Inténtalo nuevamente."
        );
      }
    } catch (error) {
      setErrMsg(
        error.response
          ? error.response.data.message
          : "Error al recuperar la contraseña"
      );
    }
  };

  return (
    <div className="App">
      <div className="login-navbar-container">
        <div className="login-navbar-options-home">
          <a href="/">Inicio</a>
        </div>
        <div className="login-navbar-options">
          <h5>¿Recordaste tu contraseña?</h5>
        </div>
        <div className="login-navbar-options">
          <button className="login-navbar-btn">
            <a href="/login">Iniciar sesión</a>
          </button>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-inner">
          <div className="logo">
            <img src={logo} className="logo" alt="Logo" />
          </div>
          {errMsg && (
            <div className="error-box">
              <div className="error">{errMsg}</div>
            </div>
          )}
          {successMsg && (
            <div className="success-box">
              <div className="success">{successMsg}</div>
            </div>
          )}
          {formSubmitted ? null : (
            <>
              <div className="form-group">
                <h2>Recupera tu contraseña</h2>
              </div>
              <div className="form-group">
                <label>Correo electrónico:</label>
                <input
                  type="text"
                  id="email"
                  ref={emailRef}
                  autoComplete="on"
                  onChange={(e) => setEmail(e.target.value.trim())}
                  value={email}
                  required
                />
              </div>
              <input type="submit" value="Enviar enlace de recuperación" />
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
