import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/logo.png";

const Verify = () => {
    return (
        <div style={styles.container}>
            <img src={logo} alt="Logo" style={styles.logo} />
            <h1 style={styles.heading}>Verifica tu identidad</h1>
            <p style={styles.text}>Revisa tu correo para comprobar que eres tú.</p>
            <p style={styles.text}>Quizás el correo está en tu bandeja de spam.</p>
            <Link to="/register" style={styles.button}>Volver al Register</Link>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f1f1f1',
        color: '#20254C',
        fontFamily: 'Arial, sans-serif',
        textAlign: 'center',
    },
    logo: {
        width: '150px',
        marginBottom: '20px',
    },
    heading: {
        color: '#52519B',
        fontSize: '30px',
        marginBottom: '5px',
        lineHeight: 'normal', // Esto ajusta el espaciado entre líneas
    },
    text: {
        color: '#20254C',
    },
    button: {
        display: 'inline-block',
        padding: '10px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: '#52519B',
        border: 'none',
        borderRadius: '5px',
        textDecoration: 'none',
        marginTop: '20px',
        cursor: 'pointer',
    },
};

export default Verify;