import React from 'react';
import "../styles/app.css";
import { Link } from "react-router-dom";
import imagenNoCuentas from "../assets/no_cuentas.png";

const Cuentas = ({ cuentas, error, mostrarMonto }) => { // Renombrado para consistencia

  if (error) return <div>{error}</div>;

  return (
    <div className='cuentas-container'>
      {cuentas.length > 0 ? (
        <div className='cuentas-container-grid'>
          {cuentas.map(cuenta => (
            <Link to={cuenta._id} style={{ textDecoration: 'none' }} state={cuenta} key={cuenta._id} className='cuenta-container'>
              <div className='cuenta-container-inner'>
                <h6>Nombre cuenta:</h6>
                <h3>{cuenta.nombre}</h3>
              </div>
              <div className='cuenta-container-inner'>
                <div className={`cuenta-container-monto ${mostrarMonto ? '' : 'blurred'}`}>
                  <h4>{cuenta.codigo_moneda}</h4>
                  <h4>{cuenta.saldo_formato}</h4>
                </div>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <div className="no-cuentas-container">
          <div>
            <p>Comienza creando una cuenta <br />para hacer fluir tu dinero.</p>
          </div>
          <img src={imagenNoCuentas} alt="no hay cuentas creadas" className='image-no-cuentas' />
        </div>
      )}
    </div>
  );
};

export default Cuentas;