import React, { useEffect, useState } from 'react';
import {getGraficoAreaGastos} from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import "../../styles/app.css";

import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const GraficoAreaGastos = () => {
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });

    const PerfilContext = useAuth();
    const token = PerfilContext.auth.accessToken;

    useEffect(() => {
        const fetchData = async () => {
            const response = await getGraficoAreaGastos(token);
            if (response.status === 200) {
                setChartData(response.data);
            } else {
                console.error("Error al buscar los datos del gráfico:", response);
            }
        };

        fetchData();
    }, [token]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Gastos por Categoría'
            }
        }
    };

    const hasData = chartData.labels.length > 0 && chartData.datasets.length > 0;

    return (
        <div className="graficos-container">
            {hasData ? (
                <Doughnut data={chartData} options={options} />
            ) : (
                <p>Realiza movimientos para ver los gráficos</p>
            )}
        </div>
    );
};

export default GraficoAreaGastos;
