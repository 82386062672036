import { useRef, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "../styles/login.css";
import { postLogin } from "../api/axios";
import logo from "../assets/logo.png";
import Cookies from 'js-cookie';

const Login = () => {
  const { setAuth, persist, setPersist } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/home";

  const userRef = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [showCookieConsent, setShowCookieConsent] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  useEffect(() => {
    const persistState = localStorage.getItem("persist");
    if (persistState) {
      setPersist(persistState === "true");
    }
  }, [setPersist]);

  useEffect(() => {
    const cookieConsent = Cookies.get('cookieConsent');
    if (!cookieConsent) {
      setShowCookieConsent(true);
    }
  }, []);

  const handleCookieConsent = (consent) => {
    if (consent) {
      Cookies.set('cookieConsent', 'true', { expires: 365 });
    }
    setShowCookieConsent(false);
  };

  const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "") {
      setErrMsg("Falta el correo electrónico");
      return;
    } else if (password === "") {
      setErrMsg("Falta la contraseña");
      return;
    }

    try {
      const response = await postLogin(email, password, { withCredentials: true });
      if (response.status === 200) {
        const { accessToken, roles, nombre } = response.data;
        setAuth({ email, roles, accessToken, nombre });

        const cookieConsent = Cookies.get('cookieConsent');
        if (cookieConsent) {
          saveToLocalStorage('accessToken', accessToken);
          saveToLocalStorage('persist', persist);
        }

        setEmail("");
        setPassword("");
        navigate(from, { replace: true });
      } else {
        setErrMsg(response.response.data.message);
      }
    } catch (error) {
      setErrMsg(error.response ? error.response.data.message : "Error al iniciar sesión");
    }
  };

  const togglePersist = () => {
    setPersist((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);

  return (
    <div className="App">
      <div className="login-navbar-container">
        <div className="login-navbar-options-home">
          <a href="/">Inicio</a>
        </div>
        <div className="login-navbar-options">
          <h5>No tengo una cuenta</h5>
        </div>
        <div className="login-navbar-options">
          <button className="login-navbar-btn">
            <a href="/register">Regístrate</a>
          </button>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-inner">
          <div className="logo">
            <img src={logo} className="logo" alt="Logo" />
          </div>
          {errMsg && (
            <div className="error-box">
              <div className="error">{errMsg}</div>
            </div>
          )}
          <div className="form-group">
            <h2>Bienvenido de vuelta</h2>
          </div>
          <div className="form-group">
            <label>Correo electrónico:</label>
            <input
              type="text"
              id="username"
              ref={userRef}
              autoComplete="on"
              onChange={(e) => setEmail(e.target.value.trim())}
              value={email}
              required
            />
          </div>
          <div className="form-group" style={{ position: "relative" }}>
            <label htmlFor="password">Contraseña:</label>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              onChange={(e) => setPassword(e.target.value.trim())}
              value={password}
              required
            />
            <button
              type="button"
              onClick={toggleShowPassword}
              className="show-password-btn"
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          </div>

          <div className="persistCheck">
            <input
              type="checkbox"
              id="persist"
              onChange={togglePersist}
              checked={persist}
            />
            <label htmlFor="persist">Confiar este dispositivo</label>
          </div>

          <div className="form-group">
            <button 
              type="button" 
              className="forgot-password-btn" 
              onClick={() => navigate('/forgot-password')}
            >
              ¿Olvidaste tu contraseña?
            </button>
          </div>

          <input type="submit" value="LOGIN" />
        </div>
      </form>
      {showCookieConsent && (
        <div className="cookie-consent-banner">
          <p>Usamos cookies para mejorar tu experiencia en nuestro sitio. Al utilizar nuestro sitio, consientes el uso de cookies.</p>
          <button onClick={() => handleCookieConsent(true)} className="aceptar">Aceptar</button>
          <button onClick={() => handleCookieConsent(false)} className = "rechazar">Rechazar</button>
        </div>
      )}
    </div>
  );
};

export default Login;