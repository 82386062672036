import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

export const postLogin = async (email, password) => {
  const LOGIN_URL = `${BASE_URL}/login`;

  try {
    const response = await axios.post(
      LOGIN_URL,
      { email, password },
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const postRegister = async (
  email,
  nombre,
  apellido,
  fecha_nacimiento,
  password
) => {
  const REGISTER_URL = `${BASE_URL}/register`;

  try {
    const response = await axios.post(
      REGISTER_URL,
      JSON.stringify({ email, nombre, apellido, fecha_nacimiento, password }),
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getCuentas = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/cuentas/all`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getMonedas = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/monedas/all`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const postCrearCuenta = async (
  nombre,
  montoInicial,
  codigoMoneda,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/cuentas/create`,
      {
        nombre: nombre,
        saldo: parseInt(montoInicial, 10),
        codigo_moneda: codigoMoneda,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getDataCuenta = async (idCuenta, token) => {
  const CUENTA_URL = `${BASE_URL}/api/cuentas/${idCuenta}`;

  try {
    const response = await axios.get(CUENTA_URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getMovimientos = async (idCuenta, page, token) => {
  const MOVIMIENTOS_URL = `${BASE_URL}/api/cuentas/movimientos/${idCuenta}?page=${page}&limit=10`;

  try {
  const response = await axios.get(MOVIMIENTOS_URL, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  });
  return response;
} catch (error) {
  return error;
}
};

export const postMovimiento = async (endpoint, data, token) => {
  try {
    const response = await axios.post(`${BASE_URL}/${endpoint}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const deleteCuenta = async (cuentaId, token) => {
  try {
    const response = await axios.delete(`${BASE_URL}/api/cuentas/${cuentaId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const patchMovimiento = async (
  movimientoId,
  tipo,
  monto,
  descripcion,
  fecha,
  categoria,
  token
) => {
  try {
    const remplazar = await axios.patch(
      `${BASE_URL}/api/cuentas/movimientos/${movimientoId}`,
      { tipo, monto, descripcion, fecha, categoria },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    return remplazar;
  } catch (error) {
    return error;
  }
};

export const getUserInfo = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/users`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getGraficoBarrasMensual = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/graficos/barra-mensual`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getGraficoAreaGastos = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/graficos/area-gastos`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteUser = async (token) => {
  try {
    const response = await axios.delete(`${BASE_URL}/api/users`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const postDemo = async (nombre, apellido, email, motivo) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/demo`,
      { nombre, apellido, email, motivo },
      {
        headers: { "Content-Type": "application/json"},
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getTotalUsuarios = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/admin/numero-usuarios`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getUsuariosMasActivos = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/admin/usuarios-mas-activos`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getCashFlow = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/cashflow`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export const getCategorias = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/categorias`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export const postForgotPassword = async (email) => {
  try { 
    const response = await axios.post(`${BASE_URL}/forgot-password`,
      {email: email}, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const postResetPassword = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/reset-password`, {token: data.token, password: data.password}, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getRecommendations = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/recomendaciones`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};