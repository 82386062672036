import React, { useState, useEffect } from "react";
import "../styles/landingPage.css";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const LandingPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showCookieConsent, setShowCookieConsent] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const cookieConsent = Cookies.get("cookieConsent");
    if (!cookieConsent) {
      setShowCookieConsent(true);
    }
  }, []);

  const handleCookieConsent = (consent) => {
    if (consent) {
      Cookies.set("cookieConsent", "true", { expires: 365 });
    }
    setShowCookieConsent(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <nav className="navbar-landing">
        <div className="navbar-landing-logo">
          <img src="./logo-blanco.png" alt="cashFlow" />
        </div>

        <ul className={`navbar-landing-links ${isMenuOpen ? "open" : ""}`}>
          <a
            href="#"
            className={`close-btn ${isMenuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <div>x</div>
          </a>
          {/*<li><a href="#home" onClick={closeMenu}>Inicio</a></li>
            <li><a href="#services" onClick={closeMenu}>Conoce cashFlow</a></li>
            <li><a href="#about" onClick={closeMenu}>Sobre Nosotros</a></li>*/}
        </ul>

        <div className="navbar-landing-btn-container">
          <button
            className="navbar-landing-btn"
            onClick={() => navigate("/login")}
          >
            Login
          </button>
          <button
            className="navbar-landing-btn"
            onClick={() => navigate("/register")}
          >
            Register
          </button>
        </div>
      </nav>
      <div className="AppLanding">
        <div className="content">
          <section className="hero-section" id="hero">
            <div className="hero-container">
              <div className="hero-title">
                <h1>
                  Fluye con tu dinero,<br></br>domina tus finanzas
                </h1>
                <p>
                  CashFlow es una aplicación que te ayuda a llevar un control de
                  tus finanzas personales de manera fácil y rápida.
                </p>
                <button
                  className="hero-btn"
                  onClick={() => navigate("/register")}
                >
                  Crear Cuenta CashFlow
                </button>
              </div>
              <div className="hero">
                <img src="./1.png"/>
              </div>
            </div>
          </section>
          <section className="services-section" id="services">
            <div className="services-container">
              <div className="service">
                <img
                  src="./2.jpg"
                  alt="Controla tus gastos"
                />
                <h2>Gestión Precisa</h2>
                <p>
                Administra y monitorea todos tus gastos desde un solo lugar para un control total.
                </p>
              </div>
              <div className="service">
                <img
                  src="./3.jpg"
                  alt="Visualiza tus finanzas"
                />
                <h2>Claridad Financiera</h2>
                <p>
                Obtén una visión clara y sencilla de tu situación financiera con gráficos informativos.
                </p>
              </div>
              <div className="service">
                <img src="./4.jpg" alt="Establece metas" />
                <h2>Objetivos Claros</h2>
                <p>
                Define y sigue tus metas financieras con el apoyo de inteligencia artificial.
                </p>
              </div>
            </div>
          </section>
        </div>
        <footer className="footer-section">
            <div className="footer-container">
              <div className="footer-logo">
                <img src="./logo-blanco.png" alt="CashFlow Logo" />
              </div>
            </div>
            <div className="footer-bottom">
            <p>Un producto creado con 💜 por Juan Pablo Gelmi.</p>
            </div>
          </footer>
      </div>
      {showCookieConsent && (
        <div className="cookie-consent-banner">
          <p>
            Usamos cookies para mejorar tu experiencia en nuestro sitio. Al
            utilizar nuestro sitio, consientes el uso de cookies.
          </p>
          <button onClick={() => handleCookieConsent(true)} className="aceptar">
            Aceptar
          </button>
          <button
            onClick={() => handleCookieConsent(false)}
            calssName="rechazar"
          >
            Rechazar
          </button>
        </div>
      )}
    </>
  );
};

export default LandingPage;
