import { useState, useEffect } from "react";

const useMostrarMonto = () => {
  const [mostrarMonto, setMostrarMonto] = useState(() => {
    const saved = localStorage.getItem("mostrarMonto");
    return saved !== null ? JSON.parse(saved) : true;
  });

  useEffect(() => {
    localStorage.setItem("mostrarMonto", JSON.stringify(mostrarMonto));
  }, [mostrarMonto]);

  const toggleMostrarMonto = () => {
    setMostrarMonto((prevMostrarMonto) => !prevMostrarMonto);
  };

  return [mostrarMonto, toggleMostrarMonto];
};

export default useMostrarMonto;