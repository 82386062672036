import React from 'react';
import {deleteCuenta} from "../api/axios";
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const EliminarCuenta = ({ cuentaId }) => {
    const navigate = useNavigate();

    const PerfilContext = useAuth();
    const token = PerfilContext.auth.accessToken;

    const handleEliminarCuenta = async () => {
        window.scrollTo(0, 0);
        if (!window.confirm('¿Estás seguro de que deseas eliminar esta cuenta? Esta acción no se puede deshacer.')) {
            return;
        }

        const response = await deleteCuenta(cuentaId, token);
        if (response.status == 204) {
            alert('Cuenta eliminada exitosamente');
            navigate('/home');
        } else {
            alert('Error al eliminar la cuenta. Por favor, inténtalo de nuevo.');
        }
    };

    return (
        <button onClick={handleEliminarCuenta} className="eliminar-btn">
            Eliminar Cuenta
        </button>
    );
};

export default EliminarCuenta;
