import React, { useState, useEffect } from "react";
import { getCuentas } from "../api/axios";
import useAuth from "../hooks/useAuth";
import FormularioCrearCuenta from "./FormularioCrearCuenta";
import Cuentas from "./Cuentas";
import Cargando from "./Cargando";
import ojo from "../assets/ojo.png";
import ojocerrado from "../assets/ojo-cerrado.png";
import useMostrarMonto from "../hooks/useMostrarMonto"; // Importa el hook personalizado

const Home = () => {
  const [cuentas, setCuentas] = useState([]);
  const [error, setError] = useState("");
  const [cargando, setCargando] = useState(true);
  const [mostrarMonto, toggleMostrarMonto] = useMostrarMonto(); // Usa el hook personalizado

  const PerfilContext = useAuth();
  const token = PerfilContext.auth.accessToken;

  const fetchCuentas = async () => {
    const response = await getCuentas(token);
    if (response.status === 200) {
      setCuentas(response.data);
      setCargando(false);
    } else {
      const errorMsg = response.response.data.message;
      setError(errorMsg);
      setCargando(false);
    }
  };

  useEffect(() => {
    fetchCuentas();
  }, [token]);

  const handleNuevaCuenta = (nuevaCuenta) => {
    setCuentas((prevCuentas) => [...prevCuentas, nuevaCuenta]);
  };

  return (
    <div className="home-container">
      {cargando ? (
        <Cargando />
      ) : (
        <>
          <div className="banner-home-btn">
            <FormularioCrearCuenta onNuevaCuenta={handleNuevaCuenta} />
            <div className="crear-cuenta-container">
              <button onClick={toggleMostrarMonto}>
                <div className="ojo">
                  {mostrarMonto ? (
                    <img src={ojo} className="ojo" />
                  ) : (
                    <img src={ojocerrado} className="ojo" />
                  )}
                </div>
              </button>
            </div>
          </div>
          <Cuentas cuentas={cuentas} error={error} mostrarMonto={mostrarMonto} />
        </>
      )}
    </div>
  );
};

export default Home;