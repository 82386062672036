import React, { useEffect, useState } from 'react';
import { getMonedas, postCrearCuenta } from "../api/axios";
import "../styles/app.css";
import useAuth from '../hooks/useAuth';

const FormularioCrearCuenta = ({ onNuevaCuenta }) => {
    const [mostrarFormulario, setMostrarFormulario] = useState(false);
    const [nombre, setNombre] = useState('');
    const [montoInicial, setMontoInicial] = useState("");
    const [codigoMoneda, setCodigoMoneda] = useState('CLP');
    const [error, setError] = useState('');
    const [animation, setAnimation] = useState('fadeIn');
    const [monedas, setMonedas] = useState([]);

    const PerfilContext = useAuth();
    const token = PerfilContext.auth.accessToken;

    useEffect(() => {
        const fetchMonedas = async () => {
            const response = await getMonedas(token);
            if (response.status === 200) {
                setMonedas(response.data);
                setCodigoMoneda(response.data[0].codigo);
            } else {
                setError(response.response.data.message);
            }
        };
        fetchMonedas();
    }, [token]);

    function esNumeroNatural(cadena) {
        return /^\d+$/.test(cadena) && parseInt(cadena, 10) >= 0;
    }

    const crearCuenta = async () => {
        if (!nombre || !montoInicial) {
            setError('Todos los campos son obligatorios');
            return;
        } else if (!esNumeroNatural(montoInicial)) {
            setError('El monto inicial debe ser un número mayor a 0');
            return;
        } else if (montoInicial < 0 || isNaN(montoInicial)) {
            setError('El monto inicial debe ser 0 o mayor a 0');
            return;
        } else {
            const response = await postCrearCuenta(nombre, montoInicial, codigoMoneda, token);
            if (response.status === 201) {
                onNuevaCuenta(response.data); // Llama la función de callback pasando la nueva cuenta
                setNombre('');
                setMontoInicial("");
                setCodigoMoneda('CLP');
                setMostrarFormulario(false);
            } else {
                setError(response.response.data.message);
            }
        }
    };

    function handleChange(e) {
        e.preventDefault();
        setCodigoMoneda(e.target.value);
    }

    const handleFormulario = () => {
        setAnimation('fadeOut');
        setTimeout(() => {
            setMostrarFormulario(false);
            setAnimation('fadeIn');
            setNombre('');
            setMontoInicial('');
            setCodigoMoneda('CLP');
            setError('');
        }, 500);
    };

    return (
        <div className='crear-cuenta-container'>
            <button onClick={() => setMostrarFormulario(true)}>
                Crear Cuenta
            </button>
            {mostrarFormulario && (
                <div className={`modal-backdrop ${animation === 'fadeOut' ? 'fadeOut' : ''}`}>
                    <div className='formulario-crear-cuenta modal-content'>
                        <div className='crear-cuenta-item'>
                            <input
                                id="nombre"
                                value={nombre}
                                onChange={e => setNombre(e.target.value)}
                                placeholder='Nombre de la Cuenta'
                                required
                            />
                        </div>
                        <div className='crear-cuenta-item'>
                            <input
                                id="saldo"
                                value={montoInicial}
                                onChange={e => setMontoInicial(e.target.value)}
                                placeholder='Monto inicial'
                                required
                            />
                        </div>
                        <div className='crear-cuenta-item'>
                            <div className='tipo-moneda-container'>
                                <label htmlFor="tipoMoneda">Tipo de Moneda:</label>
                            </div>
                            <select onChange={handleChange} value={codigoMoneda}>
                                {monedas.map(moneda => (
                                    <option value={moneda.codigo.trim()} key={moneda.codigo}>
                                        {moneda.description} - {moneda.codigo}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='crear-cuenta-item'>
                            <div className='crear-cuenta-btn'>
                                <button onClick={crearCuenta}>Crear cuenta</button>
                            </div>
                            <div className='cancelar-crear-cuenta-btn'>
                                <button onClick={handleFormulario}>Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};

export default FormularioCrearCuenta;
