import React, { useEffect, useState } from 'react';
import { getTotalUsuarios, getUsuariosMasActivos } from '../api/axios';
import useAuth from '../hooks/useAuth';
import "../styles/admin.css";

const AdminTab = () => {
  const [totalUsuarios, setTotalUsuarios] = useState(0);
  const [usuariosMasActivos, setUsuariosMasActivos] = useState([]);

  const PerfilContext = useAuth();
  const token  = PerfilContext.auth.accessToken;

  useEffect(() => {
    const fetchTotalUsuarios = async () => {
      try {
        const response = await getTotalUsuarios(token);
        setTotalUsuarios(response.data.numero_usuarios);
      } catch (error) {
        console.error('Error fetching total usuarios:', error);
      }
    };

    const fetchUsuariosMasActivos = async () => {
      try {
        const response = await getUsuariosMasActivos(token);
        setUsuariosMasActivos(response.data.usuarios_mas_activos);
      } catch (error) {
        console.error('Error fetching usuarios más activos:', error);
      }
    };

    fetchTotalUsuarios();
    fetchUsuariosMasActivos();
  }, [token]);

  return (
    <div className="admin-tab">
      <h1>Admin Dashboard</h1>
      <div className="section">
        <h2>Total Usuarios</h2>
        <p>{totalUsuarios}</p>
      </div>
      <div className="section">
        <h2>Usuarios Más Activos</h2>
        <ul>
          {usuariosMasActivos.map((usuario) => (
            <li key={usuario.id_usuario}>
              {usuario.nombre} {usuario.apellidos} - {usuario.numero_visitas} visitas
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AdminTab;
