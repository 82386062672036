import React, { useState } from "react";
import { patchMovimiento } from "../api/axios";
import useAuth from "../hooks/useAuth";

const Movimientos = ({ movimientos, fetchMovimientos, fetchCuentaData, pages, setPage }) => {
  const [editIndex, setEditIndex] = useState(null);
  const [tipo, setTipo] = useState("");
  const [monto, setMonto] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [fecha, setFecha] = useState("");
  const [movimientoId, setMovimientoId] = useState("");
  const [categoria, setCategoria] = useState("");

  const PerfilContext = useAuth();
  const token = PerfilContext.auth.accessToken;

  const handleEdit = (index, movimiento) => {
    setEditIndex(index);
    setTipo(movimiento.tipo);
    setMonto(movimiento.monto);
    setDescripcion(movimiento.descripcion);
    setFecha(movimiento.fecha);
    setMovimientoId(movimiento._id);
    setCategoria(movimiento.categoria);
  };

  const handleSave = async () => {
    const response = await patchMovimiento(movimientoId, tipo, monto, descripcion, fecha, categoria, token);

    if (response.status === 200) {
      setEditIndex(null);
      fetchMovimientos();
      fetchCuentaData();
    } else {
      alert("Error al guardar el movimiento");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "tipo":
        setTipo(value);
        break;
      case "monto":
        setMonto(value);
        break;
      case "descripcion":
        setDescripcion(value);
        break;
      case "fecha":
        setFecha(value);
        break;
      case "categoria":
        setCategoria(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="movimientos-container">
      {movimientos.map((mov, index) => (
        <div key={mov._id} className="movimiento-item-container">
          {editIndex === index ? (
            <div className="movimiento-item">
              <div className="movimiento-item-form">
                <select name="tipo" value={tipo} onChange={handleChange}>
                  <option value="ganancia">Ganancia</option>
                  <option value="gasto">Gasto</option>
                </select>
                <input name="monto" value={monto} onChange={handleChange} />
                <input
                  name="descripcion"
                  value={descripcion}
                  onChange={handleChange}
                />
                <input
                  name="fecha"
                  type="date"
                  value={fecha.split("T")[0]}
                  onChange={handleChange}
                />
                <input
                  name="categoria"
                  value={categoria}
                  onChange={handleChange}
                />
                <button onClick={() => setEditIndex(null)}>Cancelar</button>
              </div>
              <button onClick={handleSave}>Guardar</button>
            </div>
          ) : (
            <div className="movimiento-item">
              <div className="movimiento-item-form">
                <h5
                  style={{ color: mov.tipo === "ganancia" ? "green" : "red" }}
                >
                  {mov.tipo}
                </h5>
                <h5>{mov.monto_formato}</h5>
                <h5>{mov.descripcion}</h5>
                <h5>
                  {new Date(mov.fecha).toLocaleDateString("es-ES", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </h5>
                <button onClick={() => handleEdit(index, mov)}>Editar</button>
              </div>
            </div>
          )}
        </div>
      ))}

      {Array.from({ length: pages }, (_, i) => i + 1).map((num) => (
        <button key={num} onClick={() => setPage(num)}>
          {num}
        </button>
      ))}
    </div>
  );
};

export default Movimientos;
