import { useState, useEffect } from "react";
import "../styles/app.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getDataCuenta,
  getMovimientos,
  postMovimiento,
  getCategorias,
} from "../api/axios";
import useAuth from "../hooks/useAuth";
import "../styles/app.css";
import Movimientos from "./Movimientos";
import EliminarCuenta from "./EliminarCuenta";
import backArrow from "../assets/left-arrow.png";
import Cargando from "./Cargando";
import useMostrarMonto from "../hooks/useMostrarMonto";
import TiposMoneda from "../config/monedas";

function capitalizeWords(string) {
  return string
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function formatearMoneda(valor, codigoMoneda) {
  const tipoMoneda = TiposMoneda.find(
    (moneda) => moneda.codigo === codigoMoneda
  );
  if (!tipoMoneda) {
    console.error("No se encontró la moneda");
  }

  return new Intl.NumberFormat(tipoMoneda.zona, {
    style: "currency",
    currency: tipoMoneda.codigo,
  }).format(valor);
}

const CuentaComponent = () => {
  const [cuenta, setCuenta] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [idCuenta, setIdCuenta] = useState("");
  const [movimientos, setMovimientos] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [monto, setMonto] = useState(""); // Monto real
  const [montoFormato, setMontoFormato] = useState(""); // Monto formateado
  const [descripcion, setDescripcion] = useState("");
  const [categoria, setCategoria] = useState("");
  const [tipoMovimiento, setTipoMovimiento] = useState("gasto");
  const [formulario, setFormulario] = useState(false);
  const [error, setError] = useState("");
  const [mostrarMonto, toggleMostrarMonto] = useMostrarMonto();
  const [categoriasGanancias, setCategoriasGanancias] = useState([]);
  const [categoriasGastos, setCategoriasGastos] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const PerfilContext = useAuth();
  const token = PerfilContext.auth.accessToken;

  const state = location.state;
  const cuentaId = state._id;

  const fetchCuentaData = async () => {
    setIsLoading(true);

    const response = await getDataCuenta(cuentaId, token);

    if (response.status === 200) {
      setCuenta(response.data);
      setIdCuenta(response.data._id);
    } else {
      navigate("/home");
    }
    setIsLoading(false);
  };

  const fetchCategorias = async () => {
    const response = await getCategorias(token);

    if (response.status === 200) {
      let categoriasGanancias = [];
      let categoriasGastos = [];
      response.data.categoriasGanancias.forEach((categoria) => {
        categoriasGanancias.push(capitalizeWords(categoria));
      });

      response.data.categoriasGastos.forEach((categoria) => {
        categoriasGastos.push(capitalizeWords(categoria));
      });

      setCategoriasGanancias(categoriasGanancias);
      setCategoriasGastos(categoriasGastos);
    } else {
      console.error("Error fetching categorias:", response);
    }
  };

  useEffect(() => {
    fetchCuentaData();
    fetchCategorias();

    if (state && cuentaId) {
      fetchCuentaData();
    } else {
      navigate("/home");
    }
  }, [navigate, state, token, formulario]);

  const fetchMovimientos = async () => {
    const response = await getMovimientos(cuentaId, page, token);

    if (response.status === 200) {
      setMovimientos(response.data.data);
      setPages(response.data.data.pages);
    } else {
      console.error("Error al buscar movimientos");
      setError(response.response.data.message);
    }
  };

  useEffect(() => {
    fetchMovimientos();
  }, [cuenta, page]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const endpoint =
      tipoMovimiento === "gasto" ? "api/cuentas/gasto" : "api/cuentas/ganancia";
    const data = {
      cuenta_id: idCuenta,
      monto: parseFloat(monto),
      descripcion,
      categoria,
    };

    const response = await postMovimiento(endpoint, data, token);
    if (response.status === 201) {
      alert(
        `${
          tipoMovimiento === "gasto" ? "Gasto" : "Ganancia"
        } agregado correctamente`
      );
      setMovimientos((prevMovimientos) => [response.data, ...prevMovimientos]);
      setMonto("");
      setMontoFormato("");
      setDescripcion("");
      setCategoria("");
      setFormulario(false);
    } else {
      setError(response.response.data.message);
    }
  };

  const handleMonto = (e) => {
    const valorSinFormato = e.target.value.replace(/[^0-9]/g, ""); // Eliminar todos los caracteres no numéricos
    setMonto(valorSinFormato);

    if (valorSinFormato) {
      const valorFormateado = formatearMoneda(
        valorSinFormato,
        cuenta.codigo_moneda
      );
      setMontoFormato(valorFormateado);
    } else {
      setMontoFormato("");
    }
  };

  const handleTipoMovimiento = (tipo) => {
    setTipoMovimiento(tipo);
    setFormulario(true);
  };

  return (
    <div className="cuenta-component">
      <div className="back-button" onClick={() => navigate(-1)}>
        <img src={backArrow} alt="Back" />
      </div>
      {isLoading ? (
        <Cargando />
      ) : (
        <div className="cuenta-details">
          {error ? <div>{error}</div> : null}
          <div className="cuenta-details-cuenta">
            <h5>Cuenta</h5>
            <h3>{cuenta.nombre}</h3>
          </div>
          <div className="cuenta-details-saldo">
            <h5>Saldo</h5>
            <h1 className={mostrarMonto ? "" : "blurred"}>
              {cuenta.saldo_formato} {cuenta.tipo_moneda}
            </h1>
            <button onClick={toggleMostrarMonto}>
              {mostrarMonto ? "Ocultar" : "Mostrar"} Monto
            </button>
          </div>
          <div className="cuenta-details-add-movimiento">
            <h6>Agregar movimiento</h6>
            {formulario ? (
              <div className="cuenta-formulario-container">
                <form onSubmit={handleSubmit} className="movimiento-form">
                  <div className="cuenta-formulario-item">
                    <input
                      type="text"
                      value={montoFormato}
                      onChange={handleMonto}
                      placeholder="Monto"
                      required
                    />
                  </div>
                  <div className="cuenta-formulario-item">
                    <input
                      type="text"
                      value={descripcion}
                      onChange={(e) => setDescripcion(e.target.value)}
                      placeholder="Descripción"
                    />
                  </div>
                  <div className="cuenta-formulario-item">
                    <select
                      value={categoria}
                      onChange={(e) => setCategoria(e.target.value)}
                      required
                    >
                      <option value="">Seleccionar categoría</option>
                      {tipoMovimiento === "gasto"
                        ? categoriasGastos.map((cat, index) => (
                            <option key={`${cat}-${index}`} value={cat}>
                              {cat}
                            </option>
                          ))
                        : categoriasGanancias.map((cat, index) => (
                            <option key={`${cat}-${index}`} value={cat}>
                              {cat}
                            </option>
                          ))}
                    </select>
                  </div>
                  <div className="cuenta-formulario-item">
                    <button type="submit">Agregar {tipoMovimiento}</button>
                  </div>
                  <div className="agregar-movimiento-cancelar">
                    <button onClick={() => setFormulario(false)}>
                      Cancelar
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <div className="cuenta-details-add-container">
                <div
                  className="cuenta-details-add-ganancia"
                  onClick={() => handleTipoMovimiento("ganancia")}
                >
                  <h1>+</h1>
                </div>
                <div
                  className="cuenta-details-add-gasto"
                  onClick={() => handleTipoMovimiento("gasto")}
                >
                  <h1>-</h1>
                </div>
              </div>
            )}
          </div>
          <div className="cuenta-details-movimientos">
            <h5>Transacciones:</h5>
            <Movimientos
              {...{
                movimientos,
                pages,
                fetchMovimientos,
                fetchCuentaData,
                setPage,
              }}
            />
          </div>
          <EliminarCuenta cuentaId={idCuenta} />
        </div>
      )}
    </div>
  );
};

export default CuentaComponent;
