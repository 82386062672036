import "../styles/cargando.css";
import React from 'react'

function Cargando() {
  return (
    <div className = "reloader-container" >
    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  )
}

export default Cargando