import useAuth from "../hooks/useAuth";
import {
  getUserInfo,
  deleteUser,
  getCashFlow,
  getRecommendations,
} from "../api/axios";
import { useNavigate } from "react-router-dom";
import GraficoBarrasMensual from "./graficos/BarrasMensual";
import GraficoAreaGastos from "./graficos/AreaGastos";
import { useEffect, useState } from "react";
import "../styles/perfil.css";
import logo from "../assets/logo.png";
import useMostrarMonto from "../hooks/useMostrarMonto"; // Importa el hook personalizado
import ojo from "../assets/ojo.png";
import ojocerrado from "../assets/ojo-cerrado.png";
import Cargando from "./Cargando";

const Perfil = () => {
  const [name, setName] = useState("");
  const [cashFlow, setCashFlow] = useState("$0");
  const [recommendations, setRecommendations] = useState(""); // Estado para almacenar las recomendaciones como texto
  const [loading, setLoading] = useState(false); // Estado para manejar el spinner
  const [mostrarMonto, toggleMostrarMonto] = useMostrarMonto(); // Usa el hook personalizado
  const navigate = useNavigate();

  const PerfilContext = useAuth();
  const token = PerfilContext.auth.accessToken;

  useEffect(() => {
    const fetchUsuarioInfo = async () => {
      const response = await getUserInfo(token);

      if (response.status === 200) {
        setName(response.data.nombre);
      } else {
        console.error("Error fetching data:", response);
      }
    };
    const fetchCashFlow = async () => {
      const response = await getCashFlow(token);

      if (response.status === 200) {
        setCashFlow(response.data.cashflow);
      } else {
        console.error("Error fetching cashFlow:", response);
      }
    };

    fetchCashFlow();
    fetchUsuarioInfo();
  }, [token]); // Solo se necesita el token en las dependencias

  const fetchRecommendations = async () => {
    setLoading(true); // Mostrar el spinner
    try {
      const response = await getRecommendations(token);

      if (response.status === 200) {
        console.log("Recommendations:", response.data.recomendaciones);
        setRecommendations(response.data.recomendaciones);
      } else {
        console.error("Error fetching recommendations:", response);
      }
    } catch (error) {
      console.error("Error fetching recommendations:", error);
    } finally {
      setLoading(false); // Ocultar el spinner
    }
  };

  const deleteAccount = async () => {
    if (
      !window.confirm(
        "¿Estás seguro de que deseas eliminar tu usuario? Esta acción no se puede deshacer."
      )
    ) {
      return;
    } else {
      const response = await deleteUser(token);
      if (response.status === 204) {
        alert("Usuario eliminado exitosamente");
        navigate("/register");
      } else {
        alert("Error al eliminar el usuario");
      }
    }
  };

  const formatFinancialAnalysis = (text) => {
    const lines = text.split('\n');
    const elements = [];

    lines.forEach((line, index) => {
      // Encabezados de sección
      if (line.startsWith('### ')) {
        elements.push(<h3 key={index}>{line.replace('### ', '')}</h3>);
      }
      // Sub-encabezados
      else if (line.startsWith('#### ')) {
        elements.push(<h4 key={index}>{line.replace('#### ', '')}</h4>);
      }
      // Listas de ítems
      else if (/^\d+\./.test(line)) {
        elements.push(<li key={index}>{line.replace(/^\d+\.\s*/, '')}</li>);
      }
      // Total general o resumen
      else if (line.startsWith('**Total') || line.startsWith('**Balance') || line.startsWith('**Porcentaje')) {
        elements.push(<p key={index}><strong>{line.replace(/\*\*/g, '')}</strong></p>);
      }
      // Fórmulas o resultados destacados
      else if (line.includes('**')) {
        const parts = line.split('**').filter(part => part);
        const formattedLine = parts.map((part, i) => (
          i % 2 === 0 ? part : <strong key={i}>{part}</strong>
        ));
        elements.push(<p key={index}>{formattedLine}</p>);
      }
      // Texto normal
      else {
        elements.push(<p key={index}>{line}</p>);
      }
    });

    return elements;
  };


  return (
    <div className="home">
      <section className="home">
        <div className="perfil-container">
          <div className="perfil-component">
            <h3>Hola {name}!</h3>
            <div className="cashflow-total">
              <img src={logo} alt="logo" />
              <h2>total:</h2>
            </div>
            <h1 className={mostrarMonto ? "" : "blurred"}>{cashFlow}</h1>
            <button onClick={toggleMostrarMonto}>
              <div className="ojo">
                {mostrarMonto ? (
                  <img src={ojo} className="ojo" alt="Mostrar monto" />
                ) : (
                  <img src={ojocerrado} className="ojo" alt="Ocultar monto" />
                )}
              </div>
            </button>
          </div>
          <div className="recomendaciones-container">
            <button onClick={fetchRecommendations}>
              Generar Recomendaciones
            </button>
            {loading ? (
              <Cargando /> // Mostrar spinner mientras se carga
            ) : recommendations ? (
              <div className="recommendations-text">
                {formatFinancialAnalysis(recommendations)}
              </div>
            ) : (
              <p>No hay recomendaciones disponibles en este momento.</p>
            )}
          </div>
          <div className="grafico-row-container">
            <GraficoBarrasMensual />
            <GraficoAreaGastos />
          </div>
          <div>
            <button onClick={deleteAccount}>Eliminar cuenta</button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Perfil;