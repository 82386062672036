import { Link } from "react-router-dom"
import { ReactComponent as Error404 } from '../assets/404.svg';

const Missing = () => {
    return (
        <div className="missing-container">
                <Error404 width={"30%"} height={"auto"}/>
            <h2>Parece que has perdido tu camino campeón</h2>
            <div className="missing-btn">
                <Link to="/home" style={{ textDecoration: 'none' }}>
                    <h2>Volver</h2>
                </Link>
            </div>
        </div>
    )
}

export default Missing
