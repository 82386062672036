import { useState } from "react";
import { Link } from "react-router-dom";
import {
  PowerSettingsNew,
  Person,
  Menu,
  TollOutlined,
} from "@material-ui/icons";
import useLogout from "../hooks/useLogout";
import "../styles/navbar.css";
import useRefreshToken from "../hooks/useRefreshToken";
import logo from "../assets/logo.png";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const logout = useLogout();

  const refresh = useRefreshToken();
  const user = useAuth();
  const roles = user.auth.roles;

  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    refresh();
  };

  const logOut = async () => {
    await logout();
    navigate("/login");
  };

  return (
    <div className="navbar">
      <div className="nav-header">
        <Link to="/home" onClick={() => refresh()}>
          <div className="logo-navbar">
            <img src={logo} />
          </div>
        </Link>
        <button className="menu-icon" onClick={toggleMenu}>
          <Menu />
        </button>
      </div>
      <div className={`links ${menuOpen ? "open" : ""}`}>
        <div className="nav-link">
          <Link to="/perfil" onClick={() => setMenuOpen(false)}>
            <Person className="navbar-icon" />
            <span className="nav-text">Perfil</span>
          </Link>
        </div>
        {roles && roles.includes(200) ? (
          <div className="nav-link">
            <Link to="/admin" onClick={() => setMenuOpen(false)}>
              <TollOutlined className="navbar-icon" />
              <span className="nav-text">Admin</span>
            </Link>
          </div>
        ) : null}

        <div className="nav-link">
          <button onClick={logOut}>
            <PowerSettingsNew className="navbar-icon" />
            <span className="nav-text">Cerrar Sesión</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
